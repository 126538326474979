/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202408220029-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface ColumnDefinitionInputV1 {
    /**
     * Name of the column. This will be used as the header for this column. It must be unique for this table
     */
    "columnName": string;
    /**
     * The list of rules that determine the resulting value of a column. At least one rule is required
     */
    "columnRules": Array<models.ColumnRuleInputV1>;
    /**
     * The type for the column
     */
    "columnType": ColumnTypeEnum;
    /**
     * The unit of measure for this column. This is only applicable to columns whose type                      is NUMERIC. If provided, this will enforce that data in this column must be in this unit. For                      example, if the user specifies Fahrenheit and the value comes in as Meters, the value will not be                      inserted. If not provided, values with different units will be allowed to be stored and an extra                       column will be added to the generated table for the UOM
     */
    "columnUom"?: string;
    "isIndexed"?: boolean;
}

export enum ColumnTypeEnum {
    UUID = 'UUID' as any,
    TEXT = 'TEXT' as any,
    NUMERIC = 'NUMERIC' as any,
    TIMESTAMPTZ = 'TIMESTAMPTZ' as any,
    BOOLEAN = 'BOOLEAN' as any
}
